<template>
  <div class="content">
    <!-- 行业select -->
    <div class="header-item">
      <span>行业：</span>
      <el-select  v-model="query.industryTag"  placeholder="请选择行业" >
        <el-option
          v-for="item in industryLists"
          :key="item.name"
          :label="item.label"
          :value="item.name">
          </el-option>
      </el-select>
    </div>
    <!-- 地区select -->
    <div class="header-item">
      <span>地区：</span>
      <el-cascader v-model="formAddress" placeholder="请选择" clearable
        :options="addressOptions"
        :props="{ checkStrictly: true }"
        @change="getList(0)"></el-cascader>
    </div>
    <!-- 公司名称，姓名搜索框 -->
    <div class="header-item">
      <el-input v-model="query.name" placeholder="请输入姓名/公司" clearable suffix-icon="el-icon-search"
        @clear="getList(0)">
      </el-input>
    </div>
    <!-- 搜索和自定义添加文章按钮 -->
    <div class="header-item">
      <el-button type="primary" icon="el-icon-search" @click="getList(0)">搜索</el-button>
    </div>

    <!--table名片广场表格  -->
    <el-table ref="multipleTable" style="margin-top: 10px"
      header-row-class-name="table-header-style"
      :data="dataList"
      :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
      <!-- 头像 -->
      <el-table-column label="头像" width="60" align="center">
        <template slot-scope="scope">
          <img style="width: 40px;height: 40px;" :src="scope.row.head_img || $configs.headImg" > 
        </template>
      </el-table-column>
      <!-- 姓名 -->
      <el-table-column label="姓名" width="150">
        <template slot-scope="scope">
          {{scope.row.username ? scope.row.username : '--'}}
        </template>
      </el-table-column>
      <!-- 手机号码 -->
      <el-table-column label="手机号" width="120" align="center">
        <template slot-scope="scope">
          {{scope.row.phone_number ? scope.row.phone_number : '--'}}
        </template>
      </el-table-column>
      <!-- 微信号 -->
      <el-table-column label="微信号" min-width="120">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.wechat || '--'" placement="bottom">
            <span>{{scope.row.wechat ? scope.row.wechat : '--'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- 公司 -->
      <el-table-column label="公司" min-width="220">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.company || '--'" placement="bottom">
            <span>{{scope.row.company ? scope.row.company : '--'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- 行业 -->
      <el-table-column label="行业" min-width="160">
        <template slot-scope="scope">
          <template v-if="scope.row.industry_tag">
            <el-tooltip effect="dark" :content="scope.row.industry_tag" placement="bottom">
              <div>
                <el-tag v-for="(item,index) in backArray(scope.row.industry_tag)" 
                  :key="index" class="roles-tag">{{item}}</el-tag>
              </div>
            </el-tooltip>
          </template>
          <template v-else>{{'--'}}</template>
        </template>
      </el-table-column>
      <!-- 岗位 -->
      <el-table-column label="岗位" min-width="150">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.position || '--'" placement="bottom">
            <span>{{scope.row.position ? scope.row.position : '--'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- 地址 -->
      <el-table-column label="地址" min-width="220">
        <template slot-scope="scope">
          <el-tooltip effect="dark" :content="scope.row.address || '--'" placement="bottom">
            <span>{{scope.row.address ? scope.row.address : '--'}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- 人气数 -->
      <el-table-column fixed="right" label="人气数" width="89" align="center" prop="visit" :sortable="'custom'">
      </el-table-column>
      <!-- 收藏数 -->
      <el-table-column fixed="right" label="收藏数" width="89" align="center"  prop="collect" :sortable="'custom'">
      </el-table-column>
      <!-- 喜欢数 -->
      <el-table-column fixed="right" label="喜欢数"  width="89" align="center" prop="raiseup" :sortable="'custom'">
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        :background="true"
        @current-change="getList"
        :current-page.sync="query.pageIndex"
        :page-size="query.pageNumber"
        layout="total, prev, pager, next, jumper"
        :total='plazaCardTotal'>
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {backArraya} from '@/js/Utils'
import options from '@/js/country-level3-data'
export default {
  data() {
    return {
      industryList:[ //行业列表
      {
          name: '全部行业',
          label: '全部行业'
        },
        {
          name: '教育行业',
          label: '教育行业'
        },
        {
          name: '美妆行业',
          label: '美妆行业'
        },
        {
          name: '广告行业',
          label: '广告行业'
        },
        {
          name: '医疗行业',
          label: '医疗行业'
        },
        {
          name: '金融行业',
          label: '金融行业'
        },
        {
          name: '汽车行业',
          label: '汽车行业'
        }
      ],
      dataList:[],//名片广场列表数据
      query: {
        pageIndex:1,
        pageNumber:6,
        name:'',//公司名称或者姓名
        orderParam:'',//排序的字段
        sort:' ',//排序的方式
        province:'',//省份 
        city:'',//城市 
        district:'',//区县
        industryTag:'',//行业
      },
      plazaCardTotal:0,//名片广场总数
      formAddress:'' ,//选中的省市区
      addressOptions: options,//地区列表
      industryLists:[],//行业列表
    }
    
  },
  created() {
    this.getList()
    this.industryLists.unshift({name:'',label:'全部行业'})
  },
  mounted() {
  },
  watch: {
    formAddress:{
      handler:function(newValue,oldValue){
        if (this.formAddress.length == 1) {
          this.query.city='';
          this.query.district='';
          this.query.province = this.formAddress[0];
        }else if(this.formAddress.length == 2) {
          this.query.city = this.formAddress[1];
          this.query.province='';
          this.query.district='';
        } else if(this.formAddress.length == 3) {
          this.query.district = this.formAddress[2];
          this.query.province='';
          this.query.city='';
        } else {
          this.query.district = '';
          this.query.province='';
          this.query.city='';
        }
      },
    }
  },
  methods: {
    //以,分割字符串，返回数组
    backArray(str) {
      return str.split(',')
    },
    //获取名片广场信息
    async getList(pageIndex) {
      //去掉空的对象
      for (const key in this.query) {
        if (this.query[key] == '') {
          delete this.query[key]
          
        }
      }
      this.query.pageIndex = pageIndex || 1;
      let res = await this.$ZGManager.orgCardList(this.query)
      if (res.status == 200){
         res.data.list.map((e) => {
          this.industryLists.push({
            name: e.industry_tag,
            label: e.industry_tag,
          });         
        });
        //数组对象去重 行业列表
        //  for (let i = 0; i < this.industryLists.length; i++) {
        //   for (let j = i+1; j < this.industryLists.length; j++) {
        //     if (this.industryLists[i].name == this.industryLists[j].name) {
        //       this.industryLists.splice(j,1);
        //       j--;
        //     } else if(this.industryLists[i].name==null) {
        //       this.industryLists.splice(i,1);
        //       j--;
        //     }            
        //   }           
        //  }

         let newobj = {};
         this.industryLists = this.industryLists.reduce((pre,curval)=> {
           newobj[curval.name] ? '':newobj[curval.name] = pre.push(curval);
           return pre;
         },[])
         this.industryLists =  this.industryLists.filter(e=> {
           return e.name!==null
         })       
      }
      this.plazaCardTotal = res.data.total_rows;
      this.dataList = res.data.list;
      },
    //改变排序方式
    changeTableSort(column) {
      // console.log(column);
      this.query.orderParam = column.prop;
      if (column.order == 'ascending') {
        this.query.sort = 'asc';
      }else if(column.order == 'descending') {
        this.query.sort = 'desc'
      }
      this.getList()
    }
  },
}
</script>
<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 50px;
  font-size: 14px;
  ::v-deep .cell{
    // width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .pagination {
    float: right;
    margin-top: 20px;
  }
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}
img {
  border-radius: 50%;

}
.roles-tag{
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>